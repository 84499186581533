import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 月账单列表接口
 * @param {Object} data
 */
export function getMonthBillList (data) {
  return request(
    {
      url: '/ooh-bill/bill/monthBill/getMonthBillList',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
/**
 * 根据账单id查看月账单的基本信息
 * @param {Object} data
 */
export function getMonthBillOutline (data) {
  return request(
    {
      url: '/ooh-bill/bill/monthBill/getMonthBillOutline',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
/**
 * 获取结算方公司
 * @param {Object} data
 */
export function getSettlementCompanyList (data) {
  return request(
    {
      url: '/ooh-bill/bill/monthBill/getSettlementCompanyList',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}

/**
 * 获取账单类型
 * @param {Object} data
 */
export function getValidBillType (data) {
  return request(
    {
      url: '/ooh-bill/bill/monthBill/getValidBillType',
      method: 'post',
      data: qs.stringify(data)
    }
  )
}
